<template>
  <div>
    <asset-header-card
      :asset="asset"
      :dominant-frequency="true"
    />

    <b-card
      style="width: 100%; height: 88vh;"
      class="rounded p-0 flex-fill mb-0 mr-1 mt-0 text-center "
    >
      <div class="custom-search d-flex justify-content-end">
        <b-form-group class="m-1">
          <b-input-group size="md">
            <b-form-input
              v-model="trainingRequest.windowStart"
              placeholder="StartTime"
              size="md"
              class="border border-primary "
              :disabled="isBusy"
            />
            <b-form-input
              v-model="trainingRequest.windowEnd"
              placeholder="EndTime"
              size="md"
              class="border border-primary "
              :disabled="isBusy"
            />
            <b-form-input
              v-model="trainingRequest.label"
              placeholder="label"
              size="md"
              class="border border-primary "
              :disabled="isBusy"
            />
            <b-form-select
              v-model="trainingRequest.type"
              :options="['state', 'fault']"
              size="md"
              class="border border-primary "
              :disabled="isBusy"
            />
            <b-input-group-append>
              <b-button
                variant="outline-primary"
                :disabled="isBusy"
                size="md"
                @click="addToTraining"
              >
                Add to Training
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-form-group class="m-1">
          <b-input-group size="sm">
            <b-input-group-prepend>
              <b-button
                variant="outline-primary"
                :disabled="isBusy"
                size="md"
                @click="getItems"
              >
                <feather-icon
                  icon="RefreshCwIcon"
                />
              </b-button>
            </b-input-group-prepend>
            <date-picker
              v-model="timeRange"
              type="datetime"
              outlined
              value-type="timestamp"
              size="md"
              style="width: 370px"
              range
              :disabled="isBusy"
              class="border border-primary rounded"
              @clear="clearTimeRange"
              @close="validateTimeRange"
            />
            <b-input-group-append>
              <b-button
                variant="outline-primary"
                :disabled="isBusy"
                size="md"
                @click="getItems"
              >
                Request Data
              </b-button>
              <b-button
                variant="outline-primary"
                :disabled="isBusy"
                size="md"
                @click="downLoadFile"
              >
                <b-icon
                  icon="download"
                />
                DownLoad Data
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
      <div
        class="d-flex flex-column justify-content-center align-items-center"
        style="overflow-x: auto;height:80vh;"
      >
        <app-echart-scatter
          :key="`${timeRange[0]}_${timeRange[1]}`"
          :style-obj="{width: '100%', height: '100%'}"
          :option-data="option"
          @brushEnd="brushEnd"
        />
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BFormGroup, BInputGroup, BInputGroupAppend, BButton, BIcon, BInputGroupPrepend, BFormInput, BFormSelect,
} from 'bootstrap-vue'
import store from '@/store/index'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line import/no-cycle
import { msEpochToDate } from '@core/utils/filter'
// eslint-disable-next-line import/no-cycle
import router from '@/router'
import { ref, onUnmounted } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import AppEchartScatter from '@core/components/charts/echart/AppEchartScatter.vue'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import DatePicker from 'vue2-datepicker'
import Papa from 'papaparse'
import echarts from 'echarts/lib/echarts'
import assetFactoryStore from '../assetsLogStore'
import AssetHeaderCard from '../components/AssetHeaderCard.vue'
import 'vue2-datepicker/index.css'
import JSZip from 'jszip'

export default {
  directives: {
    Ripple,
  },
  components: {
    AppEchartScatter,
    BCard,
    AssetHeaderCard,
    DatePicker,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BIcon,
    BFormInput,
    BFormSelect,
  },
  props: {
    threeDimensional: {
      type: Boolean,
      default: false,
    },
    telemetry: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = 'app-asset-log'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, assetFactoryStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    //   if (timer.value) {
    //     clearInterval(timer.value)
    //     window.clearInterval(timer.value)
    //     clearTimeout(timer.value)
    //   }
    })

    const now = new Date()
    const defaultTimeRange = ref([new Date(new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime() - 1).getTime(), new Date(new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1).getTime() - 1).getTime()])
    const timeRange = ref(defaultTimeRange.value)

    const toast = useToast()
    const isBusy = ref(false)
    const asset = ref({})
    const items = ref([])
    const option = ref({})
    const width = ref('100%')

    const millisecondsPerDay = 1000 * 60 * 60 * 24

    const trainingRequest = ref({
      windowStart: 0,
      windowEnd: 0,
      label: '',
      type: 'state',
      assetID: '',
      lineID: '',
      customerID: '',
      buildingID: '',
      siteID: '',
    })

    const fetchThisAssets = () => {
      isBusy.value = true
      store.dispatch('app-asset-log/fetchThisAssets', router.currentRoute.params.assetID)
        .then(response => {
          const { data } = response
          // eslint-disable-next-line prefer-destructuring
          asset.value = data[0]
          trainingRequest.value.assetID = asset.value.assetID
          trainingRequest.value.lineID = asset.value.lineID
          trainingRequest.value.customerID = asset.value.customerID
          trainingRequest.value.buildingID = asset.value.buildingID
          trainingRequest.value.siteID = asset.value.siteID
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching Asset Info',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }
    fetchThisAssets()

    const getItems = () => {
      isBusy.value = false
      const differenceInMilliseconds = Math.abs(timeRange.value[1] - timeRange.value[0])
      const differenceInDays = Math.floor(differenceInMilliseconds / millisecondsPerDay)
      console.log(differenceInDays)
      width.value = differenceInDays > 3 ? `${((differenceInDays / 3) * 100)}%` : '100%'
      store
        .dispatch('app-asset-log/fetchAssetdominantFreqLogs', {
          assetID: router.currentRoute.params.assetID, limit: 5000, startTime: timeRange.value[0], endTime: timeRange.value[1],
        })
        .then(response => {
          if (response.data) {
            items.value = response.data
            const minX = response.data.minTimeStamp
            const maxX = response.data.maxTimeStamp
            const {
              freqSeries, statusSeries, fftLabelSeries, fftScoreSeries
            } = response.data
            const minMag = 0 // response.data.minAmp
            const maxMag = 30 // response.data.maxAmp
            const { minFreq, maxFreq } = response.data
            option.value = {
              brush: {
                toolbox: ['lineX', 'keep', 'clear'],
                xAxisIndex: 0,
              },
              replaceMerge: ['xAxis', 'yAxis', 'series'],
              color: ['#dd4444', '#fec42c', '#80F1BE'],
              grid: [
                {
                  left: '10%',
                  right: '8%',
                  height: '40%',
                },
                {
                  left: '10%',
                  right: '8%',
                  top: '55%',
                  bottom: '20%',
                  height: '10%',
                },
                {
                  left: '10%',
                  right: '8%',
                  top: '70%',
                  bottom: '20%',
                  height: '20%',
                },
              ],
              toolbox: {
                feature: {
                  dataZoom: {
                    yAxisIndex: false,
                  },
                  saveAsImage: {
                    pixelRatio: 2,
                  },
                },
              },
              dataZoom: [
                {
                  realtime: true,
                  type: 'inside',
                  start: 0,
                  end: 100,
                  xAxisIndex: [0, 1],
                },
                {
                  type: 'slider',
                  bottom: '5%',
                  realtime: true,
                  start: 0,
                  end: 100,
                  xAxisIndex: [0, 1, 2, 3],
                },
              ],
              tooltip: {
                trigger: 'axis',
                axisPointer: {
                  type: 'cross',
                },
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                position(pos, params, el, elRect, size) {
                  const obj = { top: 10 }
                  obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 50
                  return obj
                },
                extraCssText: 'width: 270px',
                formatter(param) {
                  const time = msEpochToDate(param[0].value[0])
                  const value1 = param[0].value
                  const value2 = param[1].value
                  const value3 = param[2].value
                  // prettier-ignore
                  return `
                        <div class="d-flex align-items-center">
                            Date: <div class="font-weight-bold;border:1px solid lightgray;">${time}</div>
                        </div>
                        <table style="text-align:left">
                            <tr>
                                <th>Frequency</th>
                                <th>Magnitude</th>
                            </tr>
                            <tr>
                                <td style="text-align:left;border:1px solid lightgray;">${value1[1]}</td>
                                <td style="text-align:left;border:1px solid lightgray;">${value1[2]}</td>
                            </tr>
                            <tr>
                                <td style="text-align:left;border:1px solid lightgray;">${value2[1]}</td>
                                <td style="text-align:left;border:1px solid lightgray;">${value2[2]}</td>
                            </tr>
                            <tr>
                                <td style="text-align:left;border:1px solid lightgray;">${value3[1]}</td>
                                <td style="text-align:left;border:1px solid lightgray;">${value3[2]}</td>
                            </tr>
                        </table>
                    `
                },
              },
              axisPointer: {
                link: { xAxisIndex: 'all' },
                label: {
                  backgroundColor: '#777',
                },
              },
              xAxis: [
                {
                  min: minX,
                  max: maxX,
                  type: 'time',
                  name: 'Time',
                  axisLine: {
                    onZero: false,
                  },
                  axisLabel: {
                    formatter(value) {
                      return msEpochToDate(value)
                    },
                  },
                },
                {
                  min: minX,
                  max: maxX,
                  type: 'time',
                  gridIndex: 1,
                  axisLine: { onZero: false },
                  axisTick: { show: false },
                  splitLine: { show: false },
                  axisLabel: { show: false },
                },
                {
                  min: minX,
                  max: maxX,
                  type: 'time',
                  gridIndex: 2,
                  axisLine: { onZero: false },
                  axisTick: { show: false },
                  splitLine: { show: false },
                  axisLabel: { show: false },
                  scale: true,
                },
                {

                  type: 'time',
                  gridIndex: 2,

                }
              ],
              yAxis: [
                {
                  type: 'value',
                  min: (minFreq - 1).toFixed(2),
                  max: (maxFreq + 1).toFixed(2),
                  scale: false,
                  name: 'Frequency (Hz)',
                  nameLocation: 'middle',
                  nameTextStyle: {
                    padding: [0, 0, 50, 0],
                  },
                  splitNumber: 5,
                  interval: 5,
                },
                {
                  type: 'value',
                  gridIndex: 1,
                  min: -1,
                  max: 3,
                  name: 'Status',
                  nameLocation: 'middle',
                  nameTextStyle: {
                    padding: [0, 0, 50, 0],
                  },
                  scale: false,
                  splitNumber: 1,
                  interval: 1,
                },
                {
                  type: 'category',
                  scale: false,
                  gridIndex: 2,
                  axisLabel: {
                    formatter: '{value}',
                  },
                  name: 'FFT Labels',
                  nameLocation: 'middle',
                  nameTextStyle: {
                    padding: [0, 0, 50, 0],
                  },
                  splitNumber: 1,
                  interval: 1,
                },
                {
                  type: 'value',
                  scale: false,
                  gridIndex: 2,
                  axisLabel: {
                    formatter: '{value}',
                  },
                  name: 'FFT Score',
                  nameLocation: 'middle',
                  nameTextStyle: {
                    padding: [0, 0, 50, 0],
                  }
                }
              ],
              visualMap: [
                {
                  top: 50,
                  right: 10,
                  min: minMag,
                  max: maxMag,
                  dimension: 2,
                  seriesIndex: 0,
                  show: true,
                  inRange: {
                    color: ['#28c76f', '#7D3C98', '#F1C40F', '#ff9f43', '#2980B9', '#ea5455'],
                  },
                  calculable: true,
                },
                {
                  top: '45%',
                  height: '10%',
                  right: 10,
                  min: -1,
                  max: 3,
                  dimension: 1,
                  seriesIndex: 1,
                  show: true,
                  inRange: {
                    color: ['grey', '#4b4b4b', '#ff9f43', '#28c76f', '#ea5455'],
                  },
                  calculable: true,
                },
                {
                  top: '45%',
                  height: '10%',
                  right: 10,
                  min: -1,
                  max: 3,
                  dimension: 1,
                  seriesIndex: 2,
                  show: false,
                  inRange: {
                    color: ['black'],
                  },
                  calculable: false,
                },
              ],
              series: [
                {
                  symbolSize: 2,
                  xAxisIndex: 0,
                  yAxisIndex: 0,
                  visualMapIndex: 0,
                  data: freqSeries,
                  type: 'scatter',
                  sampling:{
                    type: 'lttb',
                    threshold:100 //This could be user controlled and will be passed into the seriesModel.setData(data.lttbDownSample(data.mapDimension(valueAxis.dim), threshold)); call
                  },
                  formatter(param) {
                    const { data } = param[0]
                    return [
                      `Open: ${data[0]}<br/>`,
                      `Close: ${data[1]}<br/>`,
                      `Lowest: ${data[2]}<br/>`,
                      `Highest: ${data[3]}<br/>`,
                    ].join('')

                  },
                  animation: false,
                },
                {
                  xAxisIndex: 1,
                  yAxisIndex: 1,
                  visualMapIndex: 1,
                  symbolSize: 2,
                  data: statusSeries,
                  type: 'scatter',
                  animation: false,
                },
                {
                  type: 'scatter',
                  xAxisIndex: 2,
                  yAxisIndex: 2,
                  visualMapIndex: 2,
                  symbolSize: 4,
                  data: fftLabelSeries,

                },
                {
                  type: 'scatter',
                  xAxisIndex: 3,
                  yAxisIndex: 3,
                  symbolSize: 3,
                  data: fftScoreSeries,

                }
              ],
            }
          } else {
            option.value = {
              replaceMerge: ['xAxis', 'yAxis', 'series'],
              title: {
                show: true,
                textStyle: {
                  color: 'grey',
                  fontSize: 20,
                },
                text: 'No Data',
                left: 'center',
                top: 'center',
              },
              xAxis: {
                show: false,
              },
              yAxis: {
                show: false,
              },
              series: [],
            }
          }
        })
        .catch(e => {
          console.log(e)
          toast({
            component: ToastificationContent,
            props: {
              title: 'Error fetching devices list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
        .finally(() => {
          isBusy.value = false
        })
    }

    // timer.value = window.setInterval(() => {
    //   if (!isBusy.value) {
    //     setTimeout(getItems(), 0)
    //   }
    // }, 1000 * 10)

    getItems()

    const clearTimeRange = () => {
      timeRange.value = [...defaultTimeRange.value]
      getItems()
    }

    const validateTimeRange = () => {
      if (timeRange.value.length === 2) {
        // check is range difference is less than 72 hour
        const diff = timeRange.value[1] - timeRange.value[0]
        if (diff > (720 * 3600000)) {
          toast('Please select a range of maximum 168 hour', {
            title: 'Invalid range',
            variant: 'danger',
            solid: true,
          })
          timeRange.value = [...defaultTimeRange.value]
        }
      } else {
        timeRange.value = [...defaultTimeRange.value]
      }
    }

    const brushEnd = (startData, endData) => {
      if (startData && endData) {
        trainingRequest.value.windowStart = Math.round(startData)
        trainingRequest.value.windowEnd = Math.round(endData)
      }
    }

    const toCSV = () => Papa.unparse(items.value)

    const downLoadFile = () => {
      console.log(items.value)
      const {
        freqSeries, statusSeries, fftLabelSeries, fftScoreSeries
      } = items.value
      // generate
      const sheet1CSV = Papa.unparse(freqSeries)
      const sheet2CSV = Papa.unparse(statusSeries)
      const sheet3CSV = Papa.unparse(fftLabelSeries)
      const sheet4CSV = Papa.unparse(fftScoreSeries)
      const zip = new JSZip()
      zip.file('DominantFrequency.csv', sheet1CSV)
      zip.file('Status.csv', sheet2CSV)
      zip.file('FFTLabel.csv', sheet3CSV)
      zip.file('FFTScore.csv', sheet4CSV)
      zip.generateAsync({ type: 'blob' })
        .then(blob => {
          const url = URL.createObjectURL(blob)
          // Create a download link
          const link = document.createElement('a')
          link.href = url
          link.download = `${asset.value.assetID}-${asset.value.name}.zip`
          document.body.appendChild(link)
          // Simulate a click on the link to trigger the download
          link.click()
          // Clean up the URL object
          URL.revokeObjectURL(url)
        })
      // const multisheetCSV = `${sheet1CSV}\r\n\r\n${sheet2CSV}\r\n\r\n${sheet3CSV}\r\n\r\n${sheet4CSV}`
      // const blob = new Blob([multisheetCSV], { type: 'application/csv' })
      // const link = document.createElement('a')
      // link.href = URL.createObjectURL(blob)
      // link.download = `${asset.value.name}.csv`
      // link.click()
      // URL.revokeObjectURL(link.href)
    }

    const addToTraining = () => {
      if (trainingRequest.value.windowStart && trainingRequest.value.windowEnd && trainingRequest.value.windowStart < trainingRequest.value.windowEnd && trainingRequest.value.type && trainingRequest.value.label) {
        isBusy.value = true
        store
          .dispatch('app-asset-log/addToTraining', trainingRequest.value)
          .then(resp => {
            console.log(resp)
            toast({
              component: ToastificationContent,
              props: {
                title: 'Added to training',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
          })
          .catch(e => {
            console.log(e)
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching devices list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
          .finally(() => {
            isBusy.value = false
          })
      } else {
        toast('Please select a range', {
          title: 'Invalid range',
          variant: 'danger',
          solid: true,
        })
      }
    }

    return {
      getItems,
      isBusy,
      asset,
      option,
      timeRange,
      trainingRequest,
      clearTimeRange,
      validateTimeRange,
      downLoadFile,
      brushEnd,
      addToTraining,
      width,
    }
  },
}
</script>
